// import logo from './logo.svg';
import './App.css';
import { requestMessageSign } from './services/WalletService';
import { BTC_DONATION_ADDRESS, BTC_DONATION_ADDRESS_QR_CODE_URL } from './constants/ClientConstants';
import { ChooseProvider } from './components/ChooseProvider';
import { useEffect, useState } from 'react';
import {
  getProviders,
  getProviderById,
} from "sats-connect";


const emptyCache = async () => {
  if ('caches' in window) {
    const cachesKeys = await window.caches.keys();
    cachesKeys.forEach((names) => {
      // Delete all the cache files
      names.forEach(name => {
        caches.delete(name);
      })
    });

    if (cachesKeys.length > 0) {

      // Makes sure the page reloads. Changes are only visible after you refresh.
      window.location.reload(true);

    }
  }
}

emptyCache();

function App() {

  const [address, setAddress] = useState(null);
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [signature, setSignature] = useState('');
  const [messageToSign, setMessageToSign] = useState('');
  const [notification, setNotification] = useState(null);
  const [submitedSigningRequest, setSubmitedSigningRequest] = useState(false);
  const [notificationTimeoutId, setNotificationTimeoutId] = useState(null);

  const submitMessageToSign = async () => {

    setSubmitedSigningRequest(true);

    const response = await requestMessageSign(selectedProvider, address, messageToSign).catch(err => {

      // console.log('err: ', err);

      if (err && (err.error || err.status === 'error')) {


        // alert('Error to sign message: ' + (err.error?.message || err));
        // setNotification({ message: 'Error to sign message: ' + (err.error?.message || err), status: 'error' })
        sendNotification('Error to sign message: ' + (err.error?.message || err), 'error');
        return

      } else {

        // alert('Error to sign message: ' + err || 'Unknown');
        // setNotification({ message: 'Error to sign message: ' + (err || 'Unknown'), status: 'error' })
        sendNotification('Error to sign message: ' + (err || 'Unknown'), 'error');
        return

      }

    });

    setSubmitedSigningRequest(false);

    if (!response) {
      return
    }

    setTimeout(() => {

      try {
        // console.error('response: ', response);
        // setNotification({ message: 'Message signed succesfully! Message signature copied to clipboard.', status: 'success' });
        // sendNotification('Message signed succesfully! Message signature copied to clipboard.', 'success');
        setSignature(response.result.signature);
        // console.log('signature: ', response.result.signature);
        // navigator.clipboard.writeText(response.result.signature);

      } catch (e) {
        // console.log('ERROR HANDLING SIGNATURE: ', e);
        throw e;
      }

    }, 500);

    // console.log('response: ', response);

  }

  const handleMessageToSignChange = (e) => {

    setMessageToSign(e.target.value);

  }

  // const closeNotification = (e) => {

  //   // setMessageToSign(e.target.value);

  // }

  const handleCopyDonationAddress = (e) => {

    try {

      // Copy the text inside the text field
      navigator.clipboard.writeText(BTC_DONATION_ADDRESS);

      // Alert the copied text
      // alert("Copied BTC donation address to clipboard: " + BTC_DONATION_ADDRESS);
      // setNotification({ message: "Copied BTC donation address to clipboard: " + BTC_DONATION_ADDRESS, status: 'success' });
      sendNotification("Copied BTC donation address to clipboard: " + BTC_DONATION_ADDRESS, 'success');

    } catch (error) {

    }

  }

  const handleCopySignature = (e) => {

    try {

      // Copy the text inside the text field
      navigator.clipboard.writeText(signature);

      // Alert the copied text
      // alert("Copied BTC donation address to clipboard: " + BTC_DONATION_ADDRESS);
      // setNotification({ message: "Copied BTC donation address to clipboard: " + BTC_DONATION_ADDRESS, status: 'success' });
      sendNotification('Message signed succesfully! Message signature copied to clipboard.', 'success');
      setSignature('');


    } catch (error) {

    }

  }

  useEffect(() => {

    // console.log('messageToSign: ', messageToSign);

  }, [messageToSign]);

  useEffect(() => {

    // console.log('signature: ', signature);

    if (typeof signature === 'string' && signature.length > 0) {

      document.getElementsByClassName('signatureContainer')[0].style.height = 'auto';

    } else {

      document.getElementsByClassName('signatureContainer')[0].style.height = '0';

    }

  }, [signature]);

  // useEffect(() => {

  //   console.log('notificationTimeoutId: ', notificationTimeoutId);
  //   // setNotificationTimeoutId == null? : ;
  //   // const loadingContainerEl = window.document.getElementById('loadingContainer');
  //   // loadingContainerEl.focus({ preventScroll: true });
  //   // if (loading) {

  //   //   loadingContainerEl.style.top = '0';
  //   //   loadingContainerEl.style.display = 'fixed';
  //   //   loadingContainerEl.style.padding = '2em';
  //   //   loadingContainerEl.style.height = '100vh';

  //   // } else {
  //   //   loadingContainerEl.focus({ preventScroll: false });
  //   //   loadingContainerEl.style.padding = '0';
  //   //   loadingContainerEl.style.display = 'none';
  //   //   loadingContainerEl.style.height = '0';
  //   // }

  // }, [notificationTimeoutId]);

  const sendNotification = (message, status) => {
    // setNotificationTimeoutId
    try {
      if (['success', 'error'].indexOf(status) === -1) throw new Error('Invalid notification status');
      setNotification({ message, status });
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {

    // console.log('notification: ', notification);

    if (notification != null) {

      window.document.getElementById('notification').style.height = '4em';
      window.document.getElementById('notification').style.backgroundColor = notification.status === 'error' ? 'red' : 'green';

      clearTimeout(notificationTimeoutId);

      const newNotificationTimeoutId = setTimeout(() => {

        setNotification(null);

      }, 1000 * 4);

      setNotificationTimeoutId(newNotificationTimeoutId);

    } else {

      window.document.getElementById('notification').style.height = 0;
      window.document.getElementById('notification').style.backgroundColor = '#fff';

    }

  }, [notification]);

  useEffect(() => {

    // console.log('selectedProvider SET: ', selectedProvider);

    if (selectedProvider != null) {

      const waitConnect = async () => {

        const provider = getProviderById(selectedProvider.id);

        try {

          const permissions = await provider.request("wallet_requestPermissions", undefined);
          const addresses = await provider.request("getAddresses", { purposes: ['ordinals'] });

          // console.log('addresses: ', addresses);

          setAddress(addresses.result.addresses[0].address);

        } catch (error) {

          // console.error('error: ', error);

          if (error.error.message) {

            if (error.error.code != -32601) {

              sendNotification(error.error.message, 'error');

            }

          } else {

            sendNotification(error.toString(), 'error');

          }

          try {

            const addresses = await provider.request("getAddresses", { purposes: ['ordinals'] });

            // console.log('addresses: ', addresses);

            setAddress(addresses.result.addresses[0].address);

          } catch (error) {

            sendNotification(error.error.message, 'error');
            setSelectedProvider(null);

          }

        }

      }

      waitConnect();

    }

  }, [selectedProvider]);

  useEffect(() => {

  }, [providers]);

  useEffect(() => {

    document.getElementById('messageToSignLabel').focus({ preventScroll: true });

    // const providersRef = getProviders();

    // const providersWithInfo = [];

    // for (const providerRef of providersRef) {

    //   // const providerObject = getProviderById(providerRef.id);
    //   providersWithInfo.push(providerRef);

    // }

    setTimeout(() => {
      
      setProviders(getProviders());

    }, 500);


    setInterval(() => {

      if ( providers.length == 0 ) {

        setProviders(getProviders());

      }

    }, 1000 * 2);

  }, []);

  return (
    <div className="App">
      {Array.isArray(providers) ?
        <div>
          <ChooseProvider address={address} providers={providers} selectedProvider={selectedProvider} setSelectedProvider={setSelectedProvider} />
        </div>
        :
        <></>}
      <div className='signatureContainer'>
        <span onClick={handleCopySignature} id='signature'>Click to copy signature: <br />
          {signature}</span>
      </div>
      <div className='notificationContainer'>
        <div style={{ display: 'block', overflow: 'hidden' }} >
          <span id='notification' style={{ display: 'block', overflow: 'hidden' }} >
            <div style={{ padding: '.5em', textAlign: 'center' }}>
              {notification?.message}
            </div>
          </span>
        </div>
      </div>
      <div className='messageToSignContainer'>
        <div id="donationAddressQRContainer">
          <img id="donationAddressQR" alt="Click to copy donation address" title="Click to copy donation address" onClick={handleCopyDonationAddress} src={BTC_DONATION_ADDRESS_QR_CODE_URL} />
        </div>
        <h3 id="donateContainer" style={{ margin: '1em', wordWrap: 'break-word', maxWidth: '80%' }}>Donate<br /> <span title="Click to copy donation address" id="donationAddress" onClick={handleCopyDonationAddress} style={{ wordWrap: 'break-word' }} >{BTC_DONATION_ADDRESS}</span></h3>
        <label id="messageToSignLabel" htmlFor="messageToSign">Prove ownership of a <span id='bitcoinCharSpan' style={{ fontSize: '1.75em' }}>&#8383;</span>itcoin wallet </label>
        <textarea id="messageToSign" onChange={(e) => handleMessageToSignChange(e)} value={messageToSign} type="text" title="Paste the message to be signed here" placeholder="Paste the message to be signed here" />
        <input id="requestSignSubmit" disabled={submitedSigningRequest} onClick={async () => { await submitMessageToSign() }} type="submit" value="SIGN" />
      </div>
    </div>
  );
}

export default App;
