import {
    // getProviders,
    getProviderById,
    request,
} from "sats-connect";

import QRCode from 'qrcode'

export const generateQRCode = async text => {
    try {
        return await QRCode.toDataURL(text);
    } catch (err) {
        throw err;
    }
}

export async function requestMessageSign(selectedProvider, address, message) {

    try {

        // const address = 'bc1pesar2ym24wlzaum3e0pqhu96t7u8njyw64fyfeycmmnu4whyvhpqjehluk';
        // console.log('address: "', address, '"');
        // console.log('signing message: "', message, '"');
        // console.log('signing address: "', address, '"');

        const provider = getProviderById(selectedProvider.id);

        const response = await provider.request("signMessage", {
            //   address,
            address,
            message,
        });

        // console.log('response: ', response);

        if (response?.status === "success" || response?.result?.signature) {
            // handle success response

            // signature
            // messageHash

            return response;

        } else {

            throw response;

            // if (response.error.code === RpcErrorCode.USER_REJECTION) {
            //     // handle user request cancelation
            // } else {
            //     // handle request error
            // }

        }

        // return response;

    } catch (err) {

        throw err;

    }

}

