import './style/ChooseProvider.css';
import { useEffect, useState } from 'react';

// const response = providerObject.request('getInfo', null);

export function ChooseProvider({
    address,
    providers,
    selectedProvider,
    setSelectedProvider
}) {

    // useEffect(() => {

    //     document.addEventListener('scroll', (e) => {

    //         const scrollPercentage = Math.min(1, (window.innerHeight + window.scrollY) / document.body.offsetHeight);

    //         console.log('scrollPercentage: ', scrollPercentage);

    //     });

    // }, []);

    const handleProviderClick = (providerObject) => {

        // console.log('Choosed provider: ', providerObject.name);
        setSelectedProvider(providerObject);

    }

    useEffect(() => {


    }, [providers]);

    useEffect(() => {

        // console.log('address: ', address);
        // console.log('providers: ', providers);
        // console.log('selectedProvider: ', selectedProvider);

        if (address == null) {

            document.getElementsByClassName('chooseProviderContainer')[0].style.height = 'auto';

        } else {

            // document.getElementsByClassName('chooseProviderContainer')[0].style.height = '0';

        }

    }, [address]);

    if (Array.isArray(providers) && providers.length == 0) {

        return (
            <div className='chooseProviderContainer'>
                <div style={{ color: 'red' }}>No wallet provider found. To use this site you need to have the XVerse wallet extension installed.</div>
            </div>
        );

    } else {

        return (

            address != null && typeof address !== 'undefined' ?
                // <>Selected wallet: {selectedProvider.name}</>
                <></>
                :
                <div className='chooseProviderContainer'>
                    <div>Choose a wallet to sign the message:</div>
                    <div style={{ padding: '2em' }}>
                        <div className='providersContainer' >
                            {providers.map(provider => {
                                return (
                                    <div key={provider.id} id='provider' onClick={() => { handleProviderClick(provider) }} >
                                        <img alt={provider.name+'Icon'} src={provider.icon.replace('data:image/svg;', 'data:image/svg+xml;')} style={{ height: '2em', marginRight: '1em' }} />
                                        <span>
                                            {provider.name}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
        );

    }

}